import React, { useState } from 'react'

function Waiting({ userInfo }) {
    const [detail, setDetail] = useState(false)

    return (
        <>
            <div className='flex-grow flex flex-col items-center justify-center pb-16'>
                <svg width="150" height="190" viewBox="0 0 157 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.6182 153.626C79.6305 153.626 99.0964 134.161 99.0964 110.148C99.0964 86.1358 79.6305 66.6699 55.6182 66.6699C31.6058 66.6699 12.1399 86.1358 12.1399 110.148C12.1399 134.161 31.6058 153.626 55.6182 153.626Z" fill="#FF8B66" />
                    <path d="M69.8589 189.769C93.8713 189.769 113.337 170.303 113.337 146.291C113.337 122.278 93.8713 102.812 69.8589 102.812C45.8465 102.812 26.3806 122.278 26.3806 146.291C26.3806 170.303 45.8465 189.769 69.8589 189.769Z" fill="#FF8B66" />
                    <path d="M96.9649 164.497C114.974 164.497 129.574 149.897 129.574 131.888C129.574 113.879 114.974 99.2793 96.9649 99.2793C78.9556 99.2793 64.3562 113.879 64.3562 131.888C64.3562 149.897 78.9556 164.497 96.9649 164.497Z" fill="#FF8B66" />
                    <path d="M153.444 99.2794C153.444 153.307 119.379 197.105 77.3571 197.105C35.3356 197.105 1.27026 153.307 1.27026 99.2794C1.27026 45.2512 35.3356 1.45312 77.3571 1.45312C119.379 1.45312 153.444 45.2512 153.444 99.2794ZM20.024 99.2794C20.024 139.99 45.6932 172.993 77.3571 172.993C109.021 172.993 134.691 139.99 134.691 99.2794C134.691 58.5681 109.021 25.5652 77.3571 25.5652C45.6932 25.5652 20.024 58.5681 20.024 99.2794Z" fill="#FF7732" />
                    <path d="M69.7783 117.561C70.317 117.561 70.8337 117.775 71.2146 118.156C71.5956 118.537 71.8096 119.053 71.8096 119.592V133.811C71.8096 134.349 71.5956 134.866 71.2146 135.247C70.8337 135.628 70.317 135.842 69.7783 135.842C69.2396 135.842 68.7229 135.628 68.342 135.247C67.9611 134.866 67.7471 134.349 67.7471 133.811V119.592C67.7471 119.053 67.9611 118.537 68.342 118.156C68.7229 117.775 69.2396 117.561 69.7783 117.561ZM66.7314 142.951C66.7314 143.554 66.9101 144.143 67.2449 144.644C67.5797 145.145 68.0556 145.536 68.6123 145.766C69.1691 145.997 69.7817 146.057 70.3727 145.94C70.9638 145.822 71.5067 145.532 71.9328 145.106C72.3589 144.68 72.6491 144.137 72.7666 143.546C72.8842 142.955 72.8239 142.342 72.5933 141.785C72.3627 141.229 71.9721 140.753 71.4711 140.418C70.97 140.083 70.3809 139.904 69.7783 139.904C68.9702 139.904 68.1953 140.225 67.6239 140.797C67.0525 141.368 66.7314 142.143 66.7314 142.951ZM98.2158 131.779C98.2173 132.311 98.1133 132.839 97.9098 133.33C97.7062 133.822 97.4072 134.268 97.0301 134.643L72.6424 159.034C71.8812 159.79 70.8516 160.215 69.7783 160.215C68.7051 160.215 67.6754 159.79 66.9143 159.034L42.5393 134.643C41.7826 133.882 41.3579 132.853 41.3579 131.779C41.3579 130.706 41.7826 129.676 42.5393 128.915L66.927 104.525C67.6881 103.768 68.7178 103.344 69.791 103.344C70.8643 103.344 71.8939 103.768 72.6551 104.525L97.0428 128.915C97.4176 129.292 97.7143 129.739 97.9156 130.23C98.117 130.722 98.219 131.248 98.2158 131.779ZM94.1533 131.779L69.7783 107.404L45.4033 131.779L69.7783 156.154L94.1533 131.779Z" fill="#242424" />
                </svg>
                <h1 className='text-[#FF7D3A] text-2xl mt-2 mb-4'>Waiting for security approval</h1>
                <button onClick={() => setDetail({})} className='py-1.5 px-5 rounded-full bg-[#3E7135] text-lg'>View your details</button>
            </div>

            {detail ? <section className='fixed inset-0 z-50 grid place-items-center opacAnimation2'>
                <div onClick={() => setDetail(false)} className='fixed inset-0 bg-black bg-opacity-80 cursor-pointer'></div>
                <div className='flex-grow p-3 flex flex-col gap-3 w-full max-w-sm mx-auto overflow-y-auto scroll-hidden relative  bg-black'>
                    <div>
                        <h1 className='text-xl'>Name</h1>
                        <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                            <input type="text" placeholder='Enter Name' value={userInfo?.name} className='p-2 bg-transparent w-full outline-none border-none' />
                        </div>
                    </div>
                    <div>
                        <h1 className='text-xl'>Phone Number</h1>
                        <div className='flex items-center gap-3 w-full mt-1'>
                            <div className='bg-[#474747] px-1 rounded-xl'>
                                <input type="text" placeholder='+000' value={userInfo?.code} className='p-2 bg-transparent w-16 outline-none border-none' />
                            </div>
                            <div className='bg-[#474747] px-1 rounded-xl flex-grow'>
                                <input type="text" placeholder='Enter Number' value={userInfo?.mobile} className='p-2 bg-transparent w-full outline-none border-none' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='text-xl'>Email</h1>
                        <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                            <input type="text" placeholder='Enter Email' value={userInfo?.email} className='p-2 bg-transparent w-full outline-none border-none' />
                        </div>
                    </div>

                    <div>
                        <h1 className='text-xl'>Reason for visit</h1>
                        <div className='bg-[#474747] px-3 py-1 rounded-xl mt-1'>
                            <input type="text" placeholder='Enter Selected ID’s Number' value={userInfo?.purpose} className='p-2 bg-transparent w-full outline-none border-none' />
                        </div>
                    </div>

                    <div>
                        <h1 className='text-xl'>Government ID</h1>
                        <div className='bg-[#474747] px-3 py-1 rounded-xl mt-1'>
                            <input type="text" placeholder='Enter Selected ID’s Number' value={userInfo?.idType} className='p-2 bg-transparent w-full outline-none border-none' />
                        </div>
                        <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                            <input type="text" placeholder='Enter Selected ID’s Number' value={userInfo?.idValue} className='p-2 bg-transparent w-full outline-none border-none' />
                        </div>
                    </div>
                </div>
            </section> : ''}
        </>
    )
}

export default Waiting