import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { UserService } from "../services/user.service"
import { setCompany } from "../slices/userSlice"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const useInitialLoad = () => {
    const dispatch = useDispatch();

    let loc = window.location.hostname?.split(".")
    let subdomain = loc[0]

    let { isLoading: loading, data: companyData, refetch: validateCompany } = useQuery('validateCompany', () => UserService.getCompany(subdomain), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => {
            if (res?.success && !res?.data) {
                if (loc[1] == 'visitor') {
                    // history.push('/')
                }
            }
        },
        onError: (e) => console.log(e)
    })

    useEffect(() => {
        if (loc[1] == 'visitor') {
            validateCompany()
        }
    }, [subdomain])




    useEffect(() => {
        if (companyData?.data) {
            dispatch(setCompany(companyData?.data));
        }
    }, [companyData])

    return { isLoading: loading }
}

export const useUpdateInvite = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(UserService.updateInvite, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('validateInvite')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}