import { Switch, Route } from 'react-router-dom';
import { useInitialLoad } from './hooks';
import Loader from './components/Reusable/Loader';
import UserRoute from './UserRoute';
import NotFound from './components/Reusable/NotFound';
import SubDomain from './components/Visitor/SubDomain';
import Branch from './components/Visitor/Branch';
import Home from './components/Visitor/Home';

function Routes() {

    let { isLoading } = useInitialLoad()

    if (isLoading) {
        return <Loader />
    }

    return (
        <Switch>

            <UserRoute exact path="/" component={() => <SubDomain />} />
            <UserRoute exact path="/branch" component={() => <Branch />} />
            <UserRoute exact path="/branch/:branchName" component={() => <Home />} />
            <UserRoute exact path="/branch/:branchName/:inviteId" component={() => <Home />} />

            <Route exact path="*" component={NotFound} />
        </Switch>
    );
}

export default Routes;
