import React, { useState } from 'react'
import moment from 'moment'
import { useUpdateInvite } from '../../hooks'

// aadhar card, passport, 

function Info({ userInfo, onClick }) {
    const [error, setError] = useState('')

    const [data, setData] = useState({
        name: userInfo?.name,
        email: '',
        code: userInfo?.code,
        mobile: userInfo?.mobile,
        purpose: '',
        idType: '',
        idValue: ''
    })
    const [verified, setVerified] = useState(false);

    const handleChange = (e) => { setVerified(false); setData({ ...data, [e.target.id]: e.target.value }) }

    const { mutate: updateInvite } = useUpdateInvite(() => {
        onClick();
        setData({
            name: '',
            email: '',
            code: '',
            mobile: '',
            company: '',
            purpose: '',
            idType: '',
            idValue: ''
        })
    })

    const onVerify = (e) => {
        e.preventDefault();
        if ((userInfo?.name !== data.name) || (userInfo?.code !== data.code) || (userInfo?.mobile !== data.mobile) || (userInfo?.email !== data.email) || (userInfo?.purpose !== data.purpose)) {
            return setError('invalid')
        }
        else if (!moment(userInfo?.accessDate).isSame(moment(), 'day')) {
            return setError('notToday')
        }
        else {
            setVerified(true);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        updateInvite({
            id: userInfo?.id,
            idType: data.idType,
            idValue: data.idValue
        })
    }

    return (
        <form onSubmit={onSubmit} className='flex-grow py-5 flex flex-col gap-3 w-full max-w-sm mx-auto overflow-y-auto scroll-hidden'>
            <div>
                <h1 className='text-xl'>Name</h1>
                <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                    <input type="text" placeholder='Enter Name' id='name' value={data.name} onChange={handleChange} required minLength={3} className='p-2 bg-transparent w-full outline-none border-none' />
                </div>
            </div>
            <div>
                <h1 className='text-xl'>Phone Number</h1>
                <div className='flex items-center gap-3 w-full mt-1'>
                    <div className='bg-[#474747] px-1 rounded-xl'>
                        <input type="text" placeholder='+000' id='code' value={data.code} onChange={handleChange} required minLength={2} maxLength={5} className='p-2 bg-transparent w-16 outline-none border-none' />
                    </div>
                    <div className='bg-[#474747] px-1 rounded-xl flex-grow'>
                        <input type="text" placeholder='Enter Number' id='mobile' value={data.mobile} onChange={handleChange} required minLength={8} maxLength={15} className='p-2 bg-transparent w-full outline-none border-none' />
                    </div>
                </div>
            </div>
            <div>
                <h1 className='text-xl'>Email</h1>
                <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                    <input type="email" placeholder='Enter Email' id='email' value={data.email} onChange={handleChange} required className='p-2 bg-transparent w-full outline-none border-none' />
                </div>
            </div>

            <div>
                <h1 className='text-xl'>Reason for visit</h1>
                <div className='bg-[#474747] px-3 py-1 rounded-xl mt-1'>
                    <select type="text" defaultValue={""} id='purpose' value={data.purpose} onChange={handleChange} required className=' py-1 bg-[#474747] w-full text-lg rounded-xl outline-none border-none'>
                        <option value="" disabled>Choose reason</option>
                        <option value="option 1">option 1</option>
                        <option value="option 2">option 2</option>
                        <option value="option 3">option 3</option>
                    </select>
                </div>
            </div>

            <div>
                <h1 className='text-xl'>Government ID</h1>
                <div className='bg-[#474747] px-3 py-1 rounded-xl mt-1'>
                    <select type="text" defaultValue={""} id='idType' value={data.idType} onChange={handleChange} required className=' py-1 bg-[#474747] w-full text-lg rounded-xl outline-none border-none'>
                        <option value="" disabled>Select ID</option>
                        <option value="option 1">option 1</option>
                        <option value="option 2">option 2</option>
                        <option value="option 3">option 3</option>
                    </select>
                </div>
                <div className='bg-[#474747] px-1 rounded-xl mt-1'>
                    <input type="text" placeholder='Enter Selected ID’s Number' id='idValue' value={data.idValue} onChange={handleChange} required minLength={5} maxLength={15} className='p-2 bg-transparent w-full outline-none border-none' />
                </div>
            </div>
            <button type='button' onClick={onVerify} className='w-full py-1.5 rounded-full bg-[#889C39]'>Verify</button>
            <div className='w-full border-4 border-[#216128] rounded-full'></div>
            {
                error == 'invalid' ?
                    <div className='flex items-center justify-center py-10 flex-grow'>
                        <h1 className='text-[#FF7474] text-xl'>We do not recognize you!</h1>
                    </div>
                    :
                    error == 'notToday'
                        ?
                        <div className='flex flex-col items-center justify-center py-8'>
                            <h1 className='text-[#FF7474] text-xl'>Not Today!</h1>
                            <h1 className='text-[#FF7474] text-xl'>Entry Permit date: {moment(userInfo?.accessDate).format("DD/MM/YY")}</h1>
                        </div>
                        :
                        verified ?
                            <div className='w-full flex flex-col gap-3'>
                                <div className='text-right'>
                                    <p className='text-xs font-extralight'>Entry Permit date</p>
                                    <p className='text-sm font-light'>{moment(userInfo?.accessDate).format("DD/MM/YY")}</p>
                                </div>
                                <div>
                                    <p className='text-xs font-extralight'>Visitor’s company</p>
                                    <p className='text-sm font-light'>{userInfo?.company}</p>
                                </div>
                                <div>
                                    <p className='text-xs font-extralight'>Requested By</p>
                                    <p className='text-sm font-light'>{userInfo?.employee}</p>
                                </div>
                            </div>
                            :
                            <div className='flex items-center justify-center py-6 flex-grow'>
                                <h1 className='text-[#828282] text-xl'>Fill the above required detailes</h1>
                            </div>
            }


            <div className='text-center py-4'>
                <button type='submit'
                    disabled={
                        !data.idType || data.idValue?.length < 5 || !verified
                    }
                    className='bg-green py-1.5 w-full max-w-[200px] rounded-xl'>Submit</button>
            </div>
        </form>
    )
}

export default Info