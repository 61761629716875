// export const API_URL = 'http://localhost:8000/api';
// export const API_URL = 'https://aqqess-backend-t8tt7.ondigitalocean.app/api';
export const API_URL = 'https://api.aqqess.me/api';

export const endpoints = {
  auth: '/auth',
  register: '/register',
  login: '/login',
  company: '/company',
  profile: '/profile',
  sendOtp: '/otp/send',
  verifyOtp: '/otp/verify',
  resetPassword: '/password/reset',
  updatePassword: '/password/update',
  validate: '/validate',
  employees: '/employees',
  employee: '/employee',
  sector: '/sector',
  generateQR: '/qr/generate',
  activity: '/activity',
  validateSubDomain: '/validate/subdomain',
  branch: '/branch',
  tempReq: '/request/temporary',
  validateInvite: '/validate/invite',
  invite: '/invite'
};